<template>
<div>
    <a-collapse accordion :bordered="false" class="collapses">
        <a-collapse-panel v-for="(guide,index) in guides" :header="guide.topic" class="topicGuide" :key="index+1">
            <p class="descGuide" v-html="guide.description" :style="customStyle"></p>
        </a-collapse-panel>
    </a-collapse>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
    data() {
        return {
            customStyle: ''
        };
    },
    props: ['guides'],
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    mounted() {
        this.checkMode()
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    methods: {
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('collapses').forEach(element => {
                element.classList.remove('darkmode')
            });
            this.customStyle = 'color: rgba(0,0,0,0.65)'
        },
        darkmode() {
            document.getElementsByClassName('collapses').forEach(element => {
                element.classList.add('darkmode')
            });
            this.customStyle = 'color: #ffffff'
        }
    }
}
</script>

<style scoped>
.topicGuide,
.descGuide {
    font-size: 16px;
}

@media screen and (max-width: 500px) {

    .topicGuide,
    .descGuide {
        font-size: 14px;
    }
}

.darkmode {
    background-color: #35363A !important;
}
</style>
