<template>
<div class="scope">
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
        <Login />
    </b-modal>
    <b-row cols="2" cols-sm="2">
        <b-col class="pl-0 marginTop" style="padding-right:3px" v-for="(menuList,index) in menuLists" :key="index">
            <div v-if="$vssWidth<=540" :id="'menuListBox'+index" @click="menuListBoxActive(index, menuList.href)" class="menuListBox text-left mb-3 pl-2">
                <img class="mr-2" :src="require(`@/assets/${menuList.src}`)" width="18px" />
                <span>{{menuList.menuName}}</span>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col sm="2" class="pl-0 pr-4 marginTop" v-if="$vssWidth>=1240">
            <div :id="'menuListBox'+index" @click="menuListBoxActive(index, menuList.href)" v-for="(menuList,index) in menuLists" :key="index" class="menuListBox text-left mb-3 pl-2">
                <img class="mr-2" :src="require(`@/assets/${menuList.src}`)" width="22px" />
                <span>{{menuList.menuName}}</span>
            </div>
        </b-col>
        <b-col id="showDetail" class="showDetail">
            <WriterGuide v-if="page == 'writerGuide'" />
            <WritingGuide v-if="page == 'writingGuide'" />
        </b-col>
    </b-row>
</div>
</template>

<script>
import WriterGuide from "./../elements/writer/WriterGuide"
import WritingGuide from "./../elements/writer/WritingGuide"
import Login from "./../pages/Login"
//import router from './../../router/index'
import {
    mapGetters
} from 'vuex'
export default {
    data() {
        return {
            page: "writerGuide",
            islogin: localStorage.getItem('UserInfo') != null,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            showLoginModal: false,
            menuLists: [{
                    href: 'writerGuide',
                    src: 'notepad.png',
                    menuName: 'คู่มือนักเขียน'
                },
                {
                    href: 'writingGuide',
                    src: 'notebook.png',
                    menuName: 'คู่มือการลงผลงาน'
                },
                {
                    href: 'verifyGuide',
                    src: 'statistics.png',
                    menuName: 'คู่มือตรวจสอบยอดขาย'
                },
                {
                    href: 'write',
                    src: 'file.png',
                    menuName: 'ลงผลงานนักเขียน'
                }
            ]
        };
    },
    components: {
        WriterGuide,
        WritingGuide,
        Login
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    mounted() {
        //document.title = 'คู่มือนักเขียน';
        setTimeout(() => {
            this.menuListBoxActive(0, "writerGuide")
        }, 100);
        this.checkMode()
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    methods: {
        menuListBoxActive(index, href) {
            if (index == 3) {
                //router.push("/Control/manageNovel")
                if(this.islogin == true){
                    window.open("https://writer.novelrealm.com/?ref1="+this.userInfo.auth_token+"&ref2="+this.userInfo.auth_memberid, '_blank');
                }
                else{
                    this.showLoginModal = true
                }
                
            }
            for (var n = 0; n < 4; n++) {
                document.getElementById('menuListBox' + n).classList.remove("menuListBoxActive")
            }
            document.getElementById('menuListBox' + index).classList.add("menuListBoxActive")
            this.page = href
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('showDetail').classList.remove('darkmode')
        },
        darkmode() {
            document.getElementById('showDetail').classList.add('darkmode')
        }
    }
};
</script>

<style scoped>
#menuListBox3 {
    background-color: #F5D087;
    color: #ffffff;
    font-weight: bold;
}

.container {
    padding: 0% 1%;
}

.scope {
    padding: 3% 10% 5% 10%;
}

.showDetail {
    background-color: #ffffff;
}

.shelf {
    border: 1rem solid #A67864;
    border-radius: 10px;
    background: #FBF2EE;
}

.shelfDivider {
    position: absolute;
    width: 100%;
    top: 48%;
    left: 0px;
    height: 7px;
    background: #A67864;
    box-shadow: inset -3px -2px 4px rgba(0, 0, 0, 0.25);
}

.menuListBox {
    font-size: 14px;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.menuListBox:hover {
    color: #ffffff;
    cursor: pointer;
    background-color: #EE806B;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.menuListBoxActive:hover {
    color: #EE806B;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.menuListBoxActive {
    color: #EE806B;
    font-weight: bold;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    border-color: none;
    outline: none;
}

/* @media screen and (max-width: 768px) {
    
} */
.chaptertable {
    padding: inherit;
}

.tableScope {
    background-color: #ffffff;
    padding: 4% 10%;
    font-size: 13px;
    width: 100%;
}

.headTable {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 0.5px solid rgba(143, 143, 143, 0.541);
}

@media screen and (max-width: 500px) {
    .scope {
        padding: 3% 4% 5% 4%;
    }

    .menuListBox {
        font-size: 13px;
        border-radius: 4px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .showDetail {
        height: auto;
        padding-left: 5px;
        padding-right: 5px;
    }

    .tableScope {
        padding: 4% 1%;
        font-size: 12px;
    }

}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 3% 20% 5% 20%;
    }
}

.darkmode {
    background-color: #35363A;
}

.darkmode:deep(.ant-collapse-header) {
    color: #ffffff;
}
</style>
