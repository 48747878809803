<template>
<Collapse :guides="guides" />
</template>

<script>
import Collapse from './Collapse'
export default {
    components: {
        Collapse
    },
    data() {
        return {
            guides: [{
                    topic: 'ขั้นตอนการลงผลงานนักเขียน',
                    description: `1. เลือก เมนู “ลงผลงานนักเขียน”<br><br>
                    2. เลือก เมนู “จัดการนิยาย”<br><br>
                    3. กดปุ่ม “เพิ่มนิยาย” เพื่อเข้าสู่หน้าจัดการข้อมูล<br><br>
                    4. ใส่ชื่อเรื่อง / เนื้อหาบทนำ / และ วาง URL ภาพปกนิยาย ( ใช้วิธีฝากรูปบนเว็บรับฝากภาพแล้วทำการคัดลอกURLมาวางในช่องภาพปกนิยาย )<br><br>
                    5. กดปุ่ม “บันทึก”<br><br>
                    6. กลับมาที่หน้าจัดการนิยาย<br><br>
                    7. กดปุ่ม “เพิ่มตอน” เพื่อลงนิยายตอนที่ 1<br><br>
                    หมายเหตุ: &emsp;ปุ่ม “จัดการตอน” สำหรับเพิ่มตอนหรือแก้ไขนิยายตอนที่ลงไปแล้ว<br>
                            &emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; ปุ่ม “แก้ไข” สำหรับแก้หน้าบทนำและภาพปก <br>
                            &emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; ปุ่ม “ลบ”  สำหรับลบนิยายของตนเองออก<br><br> 
                    8. แผงเครื่องมือ ชื่อตอน / เลขตอน / การตั้งเวลาลง ปี ค.ศ. เดือน วัน ตัวอย่างเช่น 2019/4/2 18.00 / ช่องตั้งราคา หากต้องการจำหน่ายตอนละ 3 บาท ให้ใส่ 30 หลังจากที่วางเนื้อหาเสร็จสิ้น<br><br>
                    9. กดปุ่ม “บันทึก” จึงจะเสร็จสิ้นขั้นตอนการลงนิยาย`
                },
                {
                    topic: 'สิ่งที่นักเขียนจะต้องส่งให้ NovelRealm',
                    description: `1. หน้าสมุดบัญชีรับเงิน<br><br>
                    2. ชื่อ - นามสกุล<br><br>
                    3. เบอร์โทร และ Email <br><br>
                    ส่งมาที่ Manager@novelrealm.com`
                }
            ]
        };
    },
}
</script>
