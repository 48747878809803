<template>
<Collapse :guides="guides" />
</template>

<script>
import Collapse from './Collapse'
export default {
    components: {
        Collapse
    },
    data() {
        return {
            guides: [{
                    topic: 'คู่มือนักเขียน / ผลตอบแทน',
                    description: `นักเขียน สามารถนำผลงานที่สร้างสรรค์มาเผยแพร่ จำหน่าย ผ่านบนเว็บไซต์ www.novelrealm.com ที่ห้างหุ้นส่วนสามัญโนเวลเลียว์มเปิดให้ใช้บริการ โดยทางนักเขียนจะได้ส่วนแบ่ง 80% จากยอดขายผลงานที่นำมาวางจำหน่าย ซึ่งทางเราจะหักค่าบริการ 20%<br><br> 
                    ( กรณีที่มีการหัก ณ ที่จ่าย จะมีหนังสือแนบไปให้ ) <br><br>
                    ขั้นตอนการสมัคร สร้าง User ผู้ใช้ระบบทั่วไป เลือกหัวข้อนักเขียน เพื่อเข้าสู่หน้าเพิ่มนิยาย กรอกข้อมูลต่างๆตามช่องที่ระบุไว้ ได้แก่ ชื่อนิยาย , นามปากกา , เพิ่ม URL ปกนิยาย , บทนำ  <br><br>
                    เมื่อทำการเพิ่มนิยายเสร็จสิ้น นักเขียนสามารถเริ่มลงนิยายตอนที่ 1 โดยการกดปุ่ม เพิ่มตอน จากนั้นระบบหน้าอัพเดตเนื้อหาจะปรากฏแสดง ซึ่งประกอบด้วย แทบข้อมูลหลักๆ ต่อไปนี้ เลขตอน , ชื่อตอน , ราคาวางจำหน่าย และ เครื่องมือต่างๆ <br><br>
                    หลังจากที่เริ่มวางจำหน่ายนักเขียนสามารถเข้าไปตรวจสอบยอดขายได้ด้วยตนเองทุกเมื่อ <br><br>
                    ห้างหุ้นส่วนสามัญโนเวลเลียว์มจะมีการตัดยอดให้นักเขียน"เมื่อสิ้นสุดรอบเดือนปฏิทิน" โดยเงินจะถูกโอนเข้าบัญชีอัตโนมัติ ภายใน 3 วัน นับจากวันที่ 1 ของเดือนใหม่`
                },
                {
                    topic: 'ข้อตกลง',
                    description: `- เนื้อหาที่ผู้ใช้ส่งต่อ เผยแพร่ ต้องไม่มีการร้องเรียนจากบุคคลที่สาม <br><br>
                    - เนื้อหาที่นักเขียนนำมาถ่ายทอดต่อ หรือเผยแพร่ควรมีแหล่งที่มาตามกฎหมาย และต้องเป็นเนื้อหาที่ได้รับอนุญาติจากเจ้าของลิขสิทธิ์ทั้งหมด<br><br>
                    - ผู้ใช้ยินยอมให้ ห้างหุ้นส่วนสามัญโนเวลเลียว์ม ทำการเผยแพร่และจำหน่ายส่งต่อ <br><br>
                    - หากมีการแจ้งจากเจ้าของลิขสิทธิ์ อ้างว่าผู้ใช้ได้ส่งต่อหรือเผยแพร่เนื้อหาที่ละเมิดสิทธิ์ นักเขียนผู้ใช้ยินยอมให้เรามีสิทธิดำเนินการตรวจสอบ ลบ หรือยกเลิกการเผยแพร่<br><br>
                    - ต้องไม่พาดพิงองค์กรณ์หรือสถาบันใดที่ปรากฏอยู่ในความเป็นจริง ชักนำไปสู่ความแตกแยก <br><br>
                    - นักเขียนต้องหมั่นอัพเดตผลงานอยู่สม่ำเสมอ หากไม่มีการอัพเดตผลงาน เกิน 60 วัน ทางเราสามารถยุติการเผยแพร่ และไม่อนุมัติการเบิกในรอบบิลสุดท้าย เว้นแต่จะได้รับการติดต่อแจ้งสาเหตุให้ทางเรารับทราบ`
                },
                {
                    topic: 'ข้อมูลที่นักเขียนต้องส่งมา ในแผงควบคุมนักเขียน หัวข้อ “กรอกประวัติ/ถอน',
                    description: `- ชื่อบัญชี<br>
                    - เลขบัญชี<br>
                    - ธนาคาร<br>
                    - ประเภทบัญชี<br>
                    - อีเมล์<br>
                    - เบอร์โทรติดต่อ<br><br>
                    หมายเหตุ: กดอัพโหลด สำเนาหน้าสมุดบัญชี และ สำเนาบัตรประชาชน หรือ หนังสือจัดตั้งบริษัท/ห้างหุ้นส่วนจำกัด เพื่อความสะดวกรวดเร็ว ป้องกันการตกหล่น และป้องกันผู้สวมรอยแอบอ้าง รวมไปถึงการออกหนังสือรับรอง หัก ณ ที่จ่าย`
                },
                {
                    topic: 'ระบบที่เพิ่มเติมมีดังต่อไปนี้',
                    description: `1.ระบบถอนเงินด้วยตนเอง นักเขียนทุกท่านสามารถออกคำสั่งถอนเงิน ในรอบหนึ่งเดือนได้ไม่เกิน 2 ครั้ง และจะออกคำสั่งถอนได้ต่อเมื่อมียอดขาย 2,000 บาทขึ้นไปในระบบ<br><br>
                    2.ระบบถอนเงินอัตโนมัติ โดยจะเริ่มตัดยอดหลังจากที่สิ้นสุดรอบเดือนนั้นๆ ระบบนี้สำหรับนักเขียนที่ไม่ได้ออกคำสั่งถอนเงินด้วยตนเอง<br><br>
                    3.ระบบตรวจสอบสถานะการรับโอน/ชำระ<br><br>
                    4.สามารถดาวน์โหลดประวัติการขาย / ใบเสร็จ / หนังสือรับรองหัก ณ ที่จ่าย<br><br>
                    หากมีข้อสงสัยเพิ่มสามารถติดต่อได้ที่<br>
                    manager@novelrealm.com <br>
                    https://www.facebook.com/NovelRealm/inbox/`
                },
            ]
        };
    },
}
</script>
